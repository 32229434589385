.main-container {
    margin-inline: auto;
    padding-bottom: 50px;
    width: min(100%, 1500px);

    .actions-container {
        margin-inline: auto;
        padding-top: 25px;
        width: min(1280px, 90%);

        h2 {
            margin-bottom: 10px;
        }
    }
}
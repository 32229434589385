.label {
    display: flex;
    flex-direction: row;
    height: 40px;

    p {
        margin: auto;
    }
    
    .online {
        color: green;
    }

    .offline {
        color: red;
    }
}
.alerts {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .alertsTable {
        height: fit-content;
        max-height: min(70vh, 700px);
        width: min(90vw, 623px);
        overflow-y: auto;

        table {
            min-width: 480px;
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0px;

            tr.lights {
                background-color: rgb(255, 255, 0, .5);
            }

            tr.sirens {
                background-color: rgb(255, 0, 0, .5);
            }

            tr.selected {
                background-color: rgba(0, 198, 255, 0.5);
            }

            th,
            td {
                padding: 5px;
            }

            th {
                position: sticky;
                top: 0;
                padding: 5px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                background-color: white;
            }

            td {
                border-bottom: 1px solid;
                border-right: 1px solid;
            }

            th.sort:hover,
            tr.data:hover {
                cursor: pointer;
                background-color: rgb(181, 215, 246);
            }

            th:first-child,
            td:first-child {
                border-left: 1px solid;
            }
        }
    }

    .map-container {
        width: clamp(300px, 90vw, 1030px);
        z-index: 1;

        .alerts-map {
            height: 70vh;
            width: 100%;
        }
    }
}

@media (max-width: 850px) {
    .alerts {
        flex-direction: column;
    }
}
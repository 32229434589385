.container {
    margin-inline: auto;
    width: min(1280px, 90%);

    .list {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    h1 {
        font-size: clamp(1rem, 5vw + 1rem, 4rem);
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        th, td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;

            p {
                width: fit-content;
                padding: 10px;
                margin: 0px;
            }
        }

        th {
            background-color: #f2f2f2;
        }
    }
}